import { ChangeDetectorRef, Component, ElementRef, HostListener, Injector, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AbstractComponent, Channel, Chatbot, ChatbotMenu, ChatbotService, Company, ConfirmationComponent, Department, DepartmentService, FirebaseService, HeaderTypeEnum, Pager, ResponseService, ResponseTypeEnum, Tag, TagService, Team, TeamService, Traduct, UploadTypeEnum, User, UtilHelper } from 'lib-trend-core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'chatbot-component',
  templateUrl: './chatbot.component.html',
  styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent extends AbstractComponent implements OnInit, OnChanges {
  filterControls: FormArray = new FormArray([]);

  @Input() channel: Channel;
  @ViewChild('messageTextarea') messageTextarea!: ElementRef<HTMLTextAreaElement>;
  showContainerEmoji: boolean = false;
  translator!: Traduct;

  private loadingSpinnerSubject = new BehaviorSubject<boolean>(false);
  loadingSpinner$ = this.loadingSpinnerSubject.asObservable();

  private loadingSpinnerSubjectChatbotMenu = new BehaviorSubject<boolean>(false);
  loadingSpinnerChatbotMenu$ = this.loadingSpinnerSubjectChatbotMenu.asObservable();

  chatbot: Chatbot = <Chatbot>{};
  chatbotMenu: ChatbotMenu = <ChatbotMenu>{};
  chatbotMenuIndex: number;

  channels: Array<Channel>;

  departments: Array<Department> = new Array<Department>();
  allDepartments: Array<Department> = new Array<Department>();

  users: Array<User> = new Array<User>();
  allUsers: Array<User> = new Array<User>();

  tags: Array<Tag> = new Array<Tag>();
  allTags: Array<Tag> = new Array<Tag>();

  pager: Pager<any> = new Pager<any>({ perPage: 10 });
  constructor(
    private dialog: MatDialog,
    private chatbotService: ChatbotService,
    private departmentService: DepartmentService,
    private tagService: TagService,
    private teamService: TeamService,
    private firebaseService: FirebaseService,
    public responseService: ResponseService,
    private cdRef: ChangeDetectorRef,
    injetor: Injector,
  ) {
    super(injetor)
    this.setupForm(<Chatbot>{});
  }

  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }

  ngOnInit(): void {
    this.getListDepartment();

    this.getListTag();
    this.getResponseAutomatic();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!!changes['channel']?.currentValue) {
      this.channel = changes['channel']?.currentValue;
      this.getChatbot();
    }
  }

  override ngOnDestroy(): void {
  }

  private getListDepartment(): void {
    this.departmentService.getList().subscribe({
      next: (list: Array<Department>) => {
        this.allDepartments = list;
        this.departments = list;
      },
      error: (err) => this.alertService.error(err.error.message)
    });
  }

  private getResponseAutomatic() {
    this.searchParams = {
      company: this.getIDCurrentCompany(),
      type: ResponseTypeEnum.AUTOMATIC,
      channel: this.channel._id,
    };

    this.responseService.getAll(this.pager.page, this.pager.perPage, this.searchString, this.searchParams).subscribe({
      next: (value) => {
        this.pager = value;
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  private getListTag(): void {
    this.tagService.getList().subscribe({
      next: (list: Array<Tag>) => {
        this.allTags = list;
        this.tags = list;
      },
      error: (err) => this.alertService.error(err.error.message)
    });
  }

  private getChatbot(): void {
    this.chatbotService.getChatbotByCompanyAndChannel(this.getIDCurrentCompany(), this.channel._id).subscribe({
      next: (chatbot: Chatbot) => {
        if (!!chatbot) {
          this.isNew = false;
          this.chatbot = chatbot;
          this.setupForm(chatbot);
        } else {
          this.isNew = true;
          this.setupForm(<Chatbot>{});
        }
      },
      error: (err) => this.alertService.error(err.error.message)
    });
  }

  private setupForm(chatbot: Chatbot): void {
    this.formGroup = new FormGroup({
      _id: new FormControl(chatbot._id),
      message: new FormControl(chatbot.message, [Validators.required]),
      attachment: new FormControl(chatbot.attachment, []),
      type: new FormControl(chatbot.type ?? 'text', []),
      contentType: new FormControl(chatbot.contentType ?? 'text', []),
      menus: new FormArray([]),
      actived: new FormControl(chatbot.actived ?? false, []),
    });

    this.templateVariableList.forEach((variable, index) => {
      const controlName = 'message' + (index + 1);
      this.formGroup.addControl(controlName, new FormControl(''));
    });

    this.formGroup.valueChanges.subscribe(value => {
      this.chatbot = Object.assign(this.chatbot, value);
    });

    if (!!chatbot.menus && chatbot.menus.length > 0) {
      (this.formGroup.get('menus') as FormArray).clear();
      this.filterControls.clear();

      chatbot.menus.forEach((element) => {
        (this.formGroup.get('menus') as FormArray).push(
          new FormGroup({
            option: new FormControl(element.option, []),
            title: new FormControl(element.title, []),
            attachment: new FormControl(element.attachment ?? null, []),
            message: new FormControl(element.message ?? null, []),
            department: new FormControl(element.department ?? null, []),
            user: new FormControl(element.user ?? null, []),
            tags: new FormControl(element.tags ?? null, []),
            actived: new FormControl(element.actived ?? false, []),
            type: new FormControl(element.type ?? null, []),
            contentType: new FormControl(element.contentType ?? null, []),
          })
        );
        this.filterControls.push(
          new FormGroup({
            filterTag: new FormControl(''),
            filterDepartment: new FormControl(''),
            filterUser: new FormControl('')
          })
        );
      });
    }
    if (!this.isAdmin()) {
      this.formGroup.disable();
    }
  }

  get menus() {
    return this.formGroup.controls['menus'] as FormArray;
  }

  onDepartmentChange(selectedDepartment: string, index: number) {
    this.loadUsersByDepartment(selectedDepartment, index);
  }

  private loadUsersByDepartment(idDepartment: string, index: number) {
    if (!idDepartment) return;
    this.teamService.getByDepartment(idDepartment).subscribe({
      next: (teams: Team[]) => {
        this.users = teams.length > 0 ? teams.flatMap((team: Team) => team.agents) : [];
        this.allUsers = teams.length > 0 ? teams.flatMap((team: Team) => team.agents) : [];

        if (this.users.length > 0) {
          const selectedUserId = String(this.chatbot.menus[index].user);
          if (selectedUserId) {
            const user = this.users.find(u => u._id === selectedUserId);
            if (user) {
              this.formGroup.get('menus').value[index].user = user._id;
            }
          }
        }
      },
      error: (err) => this.alertService.error(err.error.message),
    });
  }

  fetchFilter(value: string, index: number, filterType: 'tags' | 'department' | 'user'): void {
    switch (filterType) {
      case 'tags': {
        const currentControl = (this.menus.at(index) as FormGroup).get('tags');
        const currentValue = currentControl?.value || [];

        this.allTags = this.tags.filter(tag =>
          tag.title.toLowerCase().includes(value.toLowerCase()) &&
          !currentValue.includes(tag._id)
        );

        currentValue.forEach(tagId => {
          const existingTag = this.tags.find(tag => tag._id === tagId);
          if (existingTag) {
            this.allTags.push(existingTag);
          }
        });
        break;
      };
      case 'department': {
        const currentControl = (this.menus.at(index) as FormGroup).get('department');
        const currentValue = currentControl?.value || null;


        this.allDepartments = this.departments.filter(department =>
          department.name.toLowerCase().includes(value.toLowerCase()) &&
          department._id !== currentValue
        );

        const existingDepartment = this.departments.find(department => department._id === currentValue);
        if (existingDepartment) {
          this.allDepartments.push(existingDepartment);
        }
        break;
      };
      default: {
        const currentControl = (this.menus.at(index) as FormGroup).get('user');
        const currentValue = currentControl?.value || null;


        this.allUsers = this.users.filter(user =>
          user.name.toLowerCase().includes(value.toLowerCase()) &&
          user._id !== currentValue
        );

        const existingUsers = this.users.find(user => user._id === currentValue);
        if (existingUsers) {
          this.allUsers.push(existingUsers);
        }
        break;
      };
    }
  }

  getFilterControl(index: number, filterType: 'tags' | 'department' | 'user'): FormControl {
    switch (filterType) {
      case 'tags': {
        return this.filterControls.at(index).get('filterTag') as FormControl;
      };
      case 'department': {
        return this.filterControls.at(index).get('filterDepartment') as FormControl;
      };
      default: {
        return this.filterControls.at(index).get('filterUser') as FormControl;
      }
    }
  }

  onFocusDepartment(): void {
    this.allDepartments = this.departments;
  }

  onFocusTags(): void {
    this.allTags = this.tags;
  }

  onFocusUser(): void {
    this.allUsers = this.users;
  }

  addMenu(): void {
    const nextOption = this.chatbot?.menus?.length + 1 || 1;
    const nextTitle = 'Novo item ' + nextOption;
    (this.formGroup.get('menus') as FormArray).push(
      new FormGroup({
        option: new FormControl(nextOption, []),
        title: new FormControl(nextTitle, []),
        attachment: new FormControl(null, []),
        message: new FormControl(null, []),
        department: new FormControl(null, []),
        user: new FormControl(null, []),
        tags: new FormControl(null, []),
        actived: new FormControl(true, []),
        type: new FormControl(null, []),
        contentType: new FormControl(null, []),
      })
    );

    this.filterControls.push(
      new FormGroup({
        filterTag: new FormControl(''),
        filterDepartment: new FormControl(''),
        filterUser: new FormControl('')
      })
    );
  }

  removeMenu(index: number) {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });
    dialogRef.afterClosed().subscribe(result => {
      if (Boolean(result) === true) {
        (this.formGroup.get('menus') as FormArray).removeAt(index);
      }
    });
  }

  selectChatbotMenuOption(chatbotMenu: ChatbotMenu) {
    this.chatbotMenu = chatbotMenu;
    this.chatbotMenuIndex = this.chatbot.menus.findIndex((menu: ChatbotMenu) => menu.option === chatbotMenu.option) ?? 0;
    if (!!this.chatbotMenu.department) {
      this.loadUsersByDepartment(String(this.chatbotMenu.department), this.chatbotMenuIndex);
    }
  }

  onChangeFileChatbotMenu(event: any, index: number): void {
    const file: File = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          this.loadingSpinnerSubjectChatbotMenu.next(true);
          const filename = file.name + '_' + new Date().getTime();
          const contentType = file.type;
          this.firebaseService.uploadFile(file, filename, UploadTypeEnum.CHATBOT).then((snapshot: { url: string }) => {
            this.loadingSpinnerSubjectChatbotMenu.next(false);
            this.chatbot.menus[index].attachment = snapshot.url;
            this.chatbot.menus[index].type = this.helperExtension(snapshot.url);
            this.chatbot.menus[index].contentType = contentType;
            this.formGroup.patchValue({ menus: this.chatbot.menus });
          });
        } catch (error) {
          this.loadingSpinnerSubjectChatbotMenu.next(false);
          this.alertService.error('Ops! Não foi possível fazer upload do seu arquivo.');
        }
      };
      reader.readAsDataURL(file);
    }
  }

  onMidiaChange(event: Event): void {
    const input = event.target as HTMLInputElement;
    if (!input.files?.length) return;

    const file = input.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = async (e: any) => {
        try {
          this.loadingSpinnerSubject.next(true);
          this.loading = true;
          const filename = file.name + '_' + new Date().getTime();
          const contentType = file.type;
          this.firebaseService.uploadFile(file, filename, UploadTypeEnum.CHATBOT).then((snapshot: { url: string }) => {
            this.loadingSpinnerSubject.next(false);
            this.loading = false;
            const type = this.helperExtension(snapshot.url);
            this.formGroup.patchValue({ attachment: snapshot.url, type, contentType });
          });
        } catch (error) { }
      };
      reader.readAsArrayBuffer(file);
    }
  }

  removeAttachment(): void {
    if (this.chatbot.attachment) {
      this.chatbot.attachment = null;
      this.formGroup.patchValue({ attachment: null });

      if (this.chatbot._id) {
        this.chatbotService.update(this.chatbot._id, this.chatbot).subscribe({
          next: () => this.alertService.success('Arquivo removido.'),
          error: (err) => this.alertService.error(`Erro ao atualizar banco de dados: ${err.error.message}`),
        });
      } else {
        this.alertService.error('ID do chatbot não encontrado.');
      }
    }
  }

  removeAttachmentChatbotMenu(index: number): void {
    let attachment = this.chatbot?.menus[index]?.attachment;
    if (attachment) {
      this.firebaseService.deleteFile(attachment)
        .then(() => {
          this.chatbot.menus[index].attachment = null;
          this.formGroup.patchValue({ menus: this.chatbot.menus });

          if (this.chatbot._id) {
            this.chatbotService.update(this.chatbot._id, this.chatbot).subscribe({
              next: () => this.alertService.success('Arquivo removido.'),
              error: (err) => this.alertService.error(`Erro ao atualizar banco de dados: ${err.error.message}`),
            });
          } else {
            this.alertService.error('ID do chatbot não encontrado.');
          }
        })
        .catch(error => {
          this.alertService.error('Ops! Não foi possível deletar esse arquivo.');
        });
    }
  }

  attachmentChatbotMenu() {
    if (this.chatbotMenu.attachment) {
      const mediaType = this.helperExtension(this.chatbotMenu.attachment);

      switch (mediaType) {
        case 'image':
          return this.chatbotMenu.attachment;
        case 'audio':
          return UtilHelper.on_audio;
        case 'video':
          return UtilHelper.on_video;
        case 'document':
          return UtilHelper.on_pdf;
        default:
          return UtilHelper.on_file;
      }
    } else {
      return UtilHelper.no_image_upload;
    }
  }

  attachmentChatbot(): string {
    const midia = this.formGroup.get('attachment')?.value;

    if (midia) {
      const mediaType = this.helperExtension(midia);

      switch (mediaType) {
        case 'image':
          return midia;
        case 'audio':
          return UtilHelper.on_audio;
        case 'video':
          return UtilHelper.on_video;
        case 'document':
          return UtilHelper.on_pdf;
        default:
          return UtilHelper.on_file;
      }
    } else {
      return UtilHelper.no_image_upload;
    }
  }

  private helperExtension(midia: string): 'image' | 'audio' | 'video' | 'document' | 'text' {
    const decodedUrl = decodeURIComponent(midia);

    const fileNameWithQuery = decodedUrl.split('/').pop() || '';
    const fileName = fileNameWithQuery.split('?')[0];

    const extension = fileName.split('.').pop()?.toLowerCase();

    const regex = /_(\d+)/;
    const nameWithoutPart = extension.replace(regex, '');

    switch (nameWithoutPart.trim()) {
      case 'mp4':
        return 'video';
      case 'pdf':
        return 'document';
      case 'mp3':
        return 'audio';
      case 'png':
        return 'image';
      case 'jpg':
        return 'image';
      case 'jpeg':
        return 'image'
      default:
        return 'text';
    }
  }

  save() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const isWelcomeActive = this.pager.list.some(item =>
      item.type === ResponseTypeEnum.AUTOMATIC &&
      item.header === HeaderTypeEnum.WELCOME &&
      item.activated === true
    );

    if (isWelcomeActive) {
      this.alertService.info('O chatbot não pode ser ativado enquanto a mensagem de boas-vindas está ativa.');
      return;
    }
    this.chatbot.company = { _id: super.getIDCurrentCompany() } as Company;
    this.chatbot.channel = { _id: this.channel._id } as Channel;
    console.log(isWelcomeActive)

    this.chatbotService.update(this.chatbot._id, this.chatbot).subscribe({
      next: (value) => {
        this.alertService.success('Chatbot atualizado com sucesso.');

        this.cdRef.detectChanges();
      }
    });
  }

  activedChatBotMenu(index: number): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      width: '600px',
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result !== undefined) {
        const currentActiveState = this.chatbot.menus[index].actived;
        this.chatbot.menus[index].actived = (currentActiveState === true) ? false : true;
        this.formGroup.patchValue({ menus: this.chatbot.menus });
      }
    });
    console.log(this.chatbotMenu.actived)
  }

  getMenuActivedValue(index: number): boolean {
    const menus = this.formGroup.get('menus') as FormArray;
    const menuGroup = menus.at(index) as FormGroup;
    const activedControl = menuGroup.get('actived') as FormControl;
    return activedControl.value;
  }

  setVariableInContent(variable: string): void {
    const control = this.formGroup.get('message') as FormControl;
    if (control) {
      const currentValue = control.value || '';
      control.setValue(currentValue + ' ' + variable);
    }
  }

  applyFormat(format: string) {
    const textarea = this.messageTextarea.nativeElement as HTMLTextAreaElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = textarea.value.substring(start, end);

    if (!selectedText) return;

    let formattedText = '';

    switch (format) {
      case 'bold':
        formattedText = `*${selectedText}*`;
        break;
      case 'italic':
        formattedText = `_${selectedText}_`;
        break;
      case 'underline':
        formattedText = `~${selectedText}~`;
        break;
      default:
        return;
    }

    textarea.value =
      textarea.value.substring(0, start) +
      formattedText +
      textarea.value.substring(end);

    textarea.setSelectionRange(start + formattedText.length, start + formattedText.length);
    textarea.focus();
    this.updateMessage();
  }

  updateMessage() {
    const textarea = this.messageTextarea.nativeElement as HTMLTextAreaElement;
    const messageWithoutLineBreaks = textarea.value;
    this.formGroup.patchValue({ message: messageWithoutLineBreaks });
  }

  onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const clipboardData = event.clipboardData || (window as any).clipboardData;
    let pastedData = clipboardData.getData('text/html') || clipboardData.getData('text/plain');

    pastedData = pastedData
      .replace(/<p[^>]*>/g, '\n')
      .replace(/<\/p>/g, '\n\n')
      .replace(/<li[^>]*>/g, '')
      .replace(/<\/li>/g, '\n')
      .replace(/<\/?html[^>]*>/g, '')
      .replace(/<\/?body[^>]*>/g, '')
      .replace(/<!--.*?-->/g, '')
      .replace(/<\/?br[^>]*>/g, '\n')
      .replace(/<\/?strong[^>]*>/g, '*')
      .replace(/<\/?b[^>]*>/g, '*')
      .replace(/<\/?em[^>]*>/g, '_')
      .replace(/<\/?i[^>]*>/g, '_')
      .replace(/<\/?u[^>]*>/g, '~$&~');

    pastedData = pastedData.replace(/\n{2,}/g, '\n\n').trim();

    const textarea = this.messageTextarea.nativeElement as HTMLTextAreaElement;
    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;

    textarea.value =
      textarea.value.substring(0, start) +
      pastedData +
      textarea.value.substring(end);

    textarea.setSelectionRange(start + pastedData.length, start + pastedData.length);
    textarea.focus();
    this.updateMessage();
  }

  toogleContainerEmoji(): void {
    this.showContainerEmoji = !this.showContainerEmoji;
  }

  addEmoji(event: any): void {
    const messageFg = this.formGroup.get('message');
    const currentText = messageFg.value || '';

    messageFg.setValue(currentText + event.emoji.native);
  }
}
